import React from "react";
import { Divider, Grid, styled } from "@mui/material";
import { DonationBeeButton } from "./DonationBeeButton";

const CenteredGridContainer = styled(Grid)(() => ({
  paddingTop: ".75em",
  paddingBottom: "1em",
}));

const CenteredGridItem = styled(Grid)(() => ({
  paddingTop: ".75em",
  paddingBottom: "1em",
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  height: 5,
  backgroundColor: theme.palette.primary.main,
}));

const DonationFontLargeScript = styled('p')(({ theme }) => ({
  fontSize: "2.5vw",
  fontFamily: "Bad Script",
  lineHeight: "3vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "2vh",
    lineHeight: "2vh",
  },
}));

const DonationFont = styled('p')(({ theme }) => ({
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1.2vw",
  lineHeight: "3vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
}));

const DonationFontScript = styled('span')(({ theme }) => ({
  fontSize: "1.25vw",
  fontFamily: "Bad Script",
  lineHeight: "3vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
}));

const BeeButtonSection = styled(Grid)(() => ({
  paddingBottom: "1vh",
}));


const DonateOptions: React.FC = () => {
  return (
    <div>
      <CenteredGridContainer container justifyContent="center" alignContent="center">
        {/* <Grid item xs={11}>
          <DividerStyled />
        </Grid> */}
        <Grid item xs={10}>
          <DonationFontLargeScript>Help Us Pollinate Goodness!</DonationFontLargeScript>
          <DonationFont>
            At Be The Change Gulf Coast, we're as <DonationFontScript>busy as bees</DonationFontScript> working to sweeten the lives of those in need on the Gulf Coast. Our hive thrives on your support. For just $2 a month, you can be the <DonationFontScript>honey</DonationFontScript> that makes our mission possible, creating a sweeter future for our community and beyond.
          </DonationFont>
        </Grid>
        <CenteredGridItem item xs={12}>
          <BeeButtonSection container justifyContent="center">
            <Grid item xs={6} md={3}>
              <DonationBeeButton buttonMessage="Donate $2 per month" donationAmount="2" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DonationBeeButton buttonMessage="Donate $5 per month" donationAmount="5" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DonationBeeButton buttonMessage="Donate $10 per month" donationAmount="10" />
            </Grid>
            <Grid item xs={6} md={3}>
              <DonationBeeButton buttonMessage="Donate any amount" donationAmount="" />
            </Grid>
          </BeeButtonSection>
        </CenteredGridItem>
      </CenteredGridContainer>
    </div>
  );
};

export default DonateOptions;
